<template>
  <div class="space-y-5">
    <div class="flex items-center space-x-4 mb-5">
      <vs-button @click="$router.go(-1)" border size="large">
        <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
      </vs-button>
      <h1 class="page_title">{{ $route.meta.title }}</h1>
    </div>
    <main v-if="is_API_loaded" class="bg-white rounded-2xl w-full pt-4">
      <section class="flex px-4 mb-2 flex-wrap sm:flex-nowrap sm:space-x-3 space-y-3 sm:space-y-0">
        <vs-input
          class="w-full"
          v-model="searchTitle"
          @blur="search"
          @keypress.enter="search"
          placeholder="以留言內容、姓名、電話或電子郵件來搜尋"
        />
        <div class="w-full flex items-center space-x-3">
          <vs-select v-if="blogs.length" placeholder="篩選部落格" v-model="filteredBlog" @change="blogOptions">
            <vs-option label="全部" value="''">全部</vs-option>
            <vs-option v-for="blog in blogs" :key="blog.sbno" :label="blog.title" :value="blog.sbno">{{ blog.title }}</vs-option>
          </vs-select>
          <vs-select :key="posts.length" v-if="posts.length" placeholder="篩選文章" v-model="filteredPost" @change="postOptions">
            <vs-option label="全部" value="''">全部</vs-option>
            <vs-option v-for="post in posts" :key="post.sbpno" :label="post.title" :value="post.sbpno">{{ post.title }}</vs-option>
          </vs-select>
          <vs-select v-else placeholder="篩選文章" disabled> </vs-select>
        </div>
      </section>

      <section class="text-sm overflow-x-auto">
        <div class="text-gray-text_dark font-medium flex items-center px-4 h-14 min-w-full w-max">
          <vs-checkbox v-if="indeterminate" indeterminate v-model="indeterminate" class="checkbox_section" @click="indeterminateHandleClick"
            ><span class="text-xs">已選取 {{ selected.length }} 筆</span></vs-checkbox
          >

          <vs-checkbox v-else class="checkbox_section" v-model="selectAll" @click="checkAllHandleClick">
            <span v-if="selectAll" class="text-xs">已選取 {{ selected.length }} 筆</span>
            <span v-else class="text-xs">共 {{ comments.length }} 筆</span>
          </vs-checkbox>

          <transition name="fade">
            <div v-if="indeterminate || selectAll" class="title_section" key="1">
              <div class="w-max border border-gray-border rounded-xl grid grid-flow-col h-8 divide-x divide-gray-border overflow-hidden">
                <button @click="delete_dialog = true" class="px-4 hover:bg-gray-background font-medium">刪除</button>
              </div>
            </div>
            <div v-else class="title_section" key="2">
              <span class="title">留言</span>
              <span class="w-64 pr-5">文章</span>
              <span class="w-40 pr-5">部落格</span>
              <span style="width: 187px">留言時間</span>
            </div>
          </transition>
        </div>

        <div class="flex flex-col divide-y divide-gray-background text-gray-text_light min-w-full w-max">
          <div
            v-for="item in comments"
            :key="item.sbpino"
            class="flex items-center py-6 hover:bg-gray-background transition-colors px-4 relative group"
          >
            <vs-checkbox
              class="checkbox_section"
              @click.stop="checkBoxHandleClick"
              v-model="selected"
              :val="item.sbpno + ',' + item.sbpino"
            ></vs-checkbox>
            <div class="title_section">
              <div class="flex flex-col title">
                <span class="text-limit-1 font-medium text-gray-text_dark">{{ item.uname }}</span>
                <span v-if="item.tel" class="text-xs text-gray-400">—— {{ item.tel }}</span>
                <span v-if="item.email" class="text-xs text-gray-400">—— {{ item.email }}</span>
                <span v-if="item.cont" class="w-fit whitespace-pre-wrap rounded-xl p-4 bg-gray-background group-hover:bg-white mt-4">{{
                  item.cont
                }}</span>
              </div>
              <div class="w-64 pr-5">{{ item.title }}</div>
              <div class="w-40 pr-5">{{ item.btitle }}</div>
              <span style="width: 187px">{{ item.cdate }}</span>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- 骨架屏區塊 -->
    <main v-else class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <skeleton />
        <skeleton class="sm:flex-shrink-0" :width="200" />
      </section>
      <section class="text-sm overflow-x-auto">
        <div v-for="(item, index) in 3" :key="index" class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-full">
          <div class="checkbox_section">
            <skeleton :width="23" :height="23" />
          </div>
          <skeleton width="100%" height="23px" />
        </div>
      </section>
    </main>

    <!-- pagination -->
    <div v-if="pagination.tpages > 1" class="flex flex-col items-center space-y-2">
      <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.tpages" />
      <span class="text-xs text-gray-400">總共 {{ pagination.pcounts }} 筆</span>
    </div>

    <!-- 刪除留言 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除 {{ selected.length }} 筆留言？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的留言無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="bathDelete" size="large" transparent color="danger" ref="deleteBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'comments',
  data() {
    return {
      is_API_loaded: false,
      comments: [],
      blogs: [], // 2.5.1 部落格列表-讀取
      posts: [], // 2.5.6 文章列表-讀取
      currentPage: 1, // 當前頁數
      searchTitle: '', // 搜尋標題input
      searchTitleBak: '',
      filteredBlog: '', // 篩選的部落格碼
      filteredPost: '', // 篩選的文章碼
      pagination: [], // 頁數資訊
      selected: [],
      selectAll: false,
      indeterminate: false,
      delete_dialog: false,
    }
  },
  computed: {
    // 把多選的checkbox陣列轉字串
    _selectedToStr() {
      // return this.selected.toString()
      let str = ''
      this.selected.forEach((item, index) => {
        let ary = item.split(',')
        let sbpno = ary[0] // 文章碼
        let sbpino = ary[1] // 表單碼
        if (index > 0) {
          item = `;${sbpno},${sbpino}`
        } else {
          item = `${sbpno},${sbpino}`
        }
        str += item
      })
      return str
    },
  },
  async created() {
    let query = this.$route.query
    this.defaultQuery = query

    this.searchTitle = query.search || ''
    this.searchTitleBak = query.search || ''
    this.filteredBlog = query.blog || ''
    this.filteredPost = query.post || ''
    this.currentPage = parseInt(query.page) || 1

    // 如果query有blog，讀取文章碼
    if (query?.blog) {
      await this.getPostsList(query.blog)
    }

    // 讀取初始資料
    const res = await Promise.all([this.loadForm(), this.getBlogsList()])

    // res會是陣列，若兩個都為true，關閉loading
    if (res.every((e) => e === true)) {
      this.is_API_loaded = true
    }
  },
  methods: {
    // 2.5.13 留言-讀取表單列表
    loadForm(arr) {
      // 深拷貝
      let routeQuery = JSON.parse(JSON.stringify(this.$route.query))

      // 新增或覆蓋進routeQuery物件
      if (arr && arr.length > 0) {
        arr.forEach((item) => {
          routeQuery[item[0]] = item[1]
        })
      }

      // 迴圈，若物件中有空值就刪除
      for (let key in routeQuery) {
        // 若值為空，刪除。
        if (routeQuery[key] === '' || routeQuery[key] === -99) {
          delete routeQuery[key]
        }
      }

      // 判斷物件是否一樣
      function objCompare(obj1, obj2) {
        const obj1_keys = Object.keys(obj1)
        const obj2_keys = Object.keys(obj2)
        if (obj1_keys.length !== obj2_keys.length) {
          return false
        }
        for (let key of obj1_keys) {
          if (obj1[key] !== obj2[key]) {
            return false
          }
        }
        return true
      }

      if (!objCompare(routeQuery, this.defaultQuery)) {
        // 不一樣，重整query
        this.$router.replace({ query: routeQuery })
        this.defaultQuery = routeQuery
      }

      return this.$axios({
        url: 'front/pages/blogs/getPagesInFormList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: routeQuery?.page || 1,
          title: routeQuery?.search || '',
          sbno: routeQuery?.blog || '', // 部落格碼
          sbpno: routeQuery?.post || '', // 文章碼
        },
      }).then((res) => {
        console.log(res, '2.5.13 留言-讀取表單列表')
        if (res.data.Success) {
          this.comments = res.data.Data
          this.pagination = res.data.Page
          return res.data.Success
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.5.1 部落格列表-讀取
    getBlogsList() {
      return this.$axios({
        url: 'front/pages/blogs/getBlogsList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: -1,
          title: '',
        },
      }).then((res) => {
        if (res.data.Success) {
          this.blogs = res.data.Data
          return res.data.Success
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 2.5.6 文章列表-讀取
    getPostsList(sbno) {
      this.$axios({
        url: 'front/pages/blogs/getPagesList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: -1,
          title: '',
          isrtime: -1,
          sbno,
        },
      }).then((res) => {
        console.log(res, '2.5.6 文章列表-讀取')
        if (res.data.Success) {
          this.posts = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 改變頁籤時觸發
    changePagination() {
      this.selected = []
      this.selectAll = false
      this.indeterminate = false
      this.loadForm([['page', this.currentPage]])
    },
    // 篩選標題時觸發
    search(e) {
      if (this.searchTitle === this.searchTitleBak) return

      this.loadForm([
        ['page', 1],
        ['search', this.searchTitle],
      ])
      this.currentPage = 1

      this.searchTitleBak = this.searchTitle
    },
    // 篩選部落格時觸發
    blogOptions(sbno) {
      // 清空文章
      this.filteredPost = ''
      this.posts = []

      this.loadForm([
        ['page', 1],
        ['blog', this.filteredBlog],
      ])

      // 重新讀取文章，若sbno為空就不讀取
      if (sbno !== "''") this.getPostsList(sbno)
    },
    // 篩選文章時觸發
    postOptions() {
      this.loadForm([
        ['page', 1],
        ['post', this.filteredPost],
      ])
    },
    // 批次操作刪除 2.5.15 留言-刪除表單
    bathDelete() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/pages/blogs/dPagesInForm.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          no: this._selectedToStr,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.selected = []
          this.indeterminate = false
          this.selectAll = false
          loading.close() // 關閉按鈕loading
          this.delete_dialog = false // 關閉dialog
          this.currentPage = 1
          this.loadForm([['page', 1]])
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close() // 關閉按鈕loading
        }
      })
    },
    checkBoxHandleClick(e) {
      setTimeout(() => {
        if (this.selected.length > 0 && this.selected.length < this.comments.length) {
          // 勾選卻沒有全選時
          this.indeterminate = true
        } else if (this.selected.length === this.comments.length) {
          // 全選時
          this.indeterminate = false
          this.$nextTick(() => {
            this.selectAll = true
          })
        } else {
          // 沒選時
          this.indeterminate = false
          this.selectAll = false
        }
      }, 0)
    },
    checkAllHandleClick(e, status) {
      let value
      e ? (value = e.target.checked) : (value = status)
      if (value) {
        for (let i = 0; i < this.comments.length; i++) {
          this.selected.push(this.comments[i].sbpno + ',' + this.comments[i].sbpino)
        }
      } else {
        this.selected = []
      }
    },
    indeterminateHandleClick(e) {
      let value = e.target.checked
      this.checkAllHandleClick(null, value)
    },
  },
}
</script>
<style lang="scss" scoped>
.checkbox_section {
  @apply w-28 flex-shrink-0;
}

.title_section {
  @apply flex items-center w-min sm:w-full;
  .title {
    @apply w-60 sm:flex-grow pr-5 md:pr-20;
  }
}
</style>
